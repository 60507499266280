export const STORE = 'store'
export const EVENT = 'event'
export const EFFECT = 'effect'
export const DOMAIN = 'domain'
export const SAMPLER = 'sampler'
export const CROSSLINK = 'crosslink'
export const MAP = 'map'
export const STACK = 'stack'
export const BARRIER = 'barrier'
export const VALUE = 'value'
export const FORK_COUNTER = 'forkInFlightCounter'
export const SAMPLE = 'sample'
export const FILTER = 'filter'
export const REG_A = 'a'
